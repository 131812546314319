import React, { useContext, useEffect, useState } from 'react';
import cls from 'classnames';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  Radio,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import _ from 'lodash';
import { useSortable } from '@dnd-kit/sortable';
import { Form, Formik } from 'formik';
import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { draggableItemStyles } from 'utils/dndItemStyle';
import { useDispatch } from 'react-redux';
import { openCommentInfoModal } from 'redux/reducers/modals';
import useDebounce from 'hooks/useDebounce';
import { ReactComponent as CompletedIcon } from '../../assets/completed.svg';
import { ReactComponent as ArrowDownIcon } from './assets/arrowDown.svg';
import { getEditCommentValidation, getEditReportCommentValidation } from './validation';
import CommentForm from './CommentForm';
import { useComment } from './useComment';
import useStyles from './useStyles';

const Comment = ({
  onOpenMenu,
  onChangeComments,
  item,
  idx,
  selectedItem,
  selectedSection,
  initialValues,
  estimations = [],
  estimationTypes = [],
  isOutlinedItem = false,
  isDeficiencies = false,
  isReport = false,
  isTermite = false,
  children,
}) => {
  const classes = useStyles({ isOutlinedItem });

  const {
    toggleIsImageLoading,
    toggleIsVideoLoading,
    toggleIsExpanded,
    submit,
    isImageLoading,
    isVideoLoading,
    isExpanded,
    setAllDeafaultValues,
  } = useComment(onChangeComments, item);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: idx + 1 });

  const style = draggableItemStyles(isDragging, transition, transform);

  const shouldShowDoneRadioButton =
    isReport &&
    (isDeficiencies ||
      item.type === 'TEXT' ||
      (isTermite && item.type === 'CHOICES' && selectedSection === 3 && selectedItem === 0));

  return (
    <Formik
      enableReinitialize
      validateOnMount={isReport}
      validationSchema={
        isReport ? getEditReportCommentValidation(isDeficiencies) : getEditCommentValidation(isDeficiencies)
      }
      initialValues={initialValues}
      onSubmit={submit}
      validateOnChange
    >
      {({ handleSubmit, isValid, values, setValues }) => (
        <Form style={{ width: '100%' }}>
          {isReport && <SubmitValues values={values} initialValues={initialValues} submit={submit} />}
          <Accordion
            expanded={isExpanded}
            onChange={toggleIsExpanded}
            className={classes.accordionWrapper}
            ref={setNodeRef}
            style={style}
            data-cy-item={`comment-${item.name}`}
            {...attributes}
            {...listeners}
          >
            <AccordionSummary
              expandIcon={isDesktop && <ArrowDownIcon />}
              className={cls(classes.columnItem, { [classes.accordionWrapperSelected]: isExpanded })}
            >
              {isReport && values.isDone && <CompletedIcon className={classes.completedIcon} />}
              {shouldShowDoneRadioButton && (
                <div
                  className={`radio-button ${values.isDone && 'selected'}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setValues({ ...values, isDone: !values.isDone });
                  }}
                >
                  <div />
                </div>
              )}
              <span className={classes.itemTitleWrapper}>
                {children}
                <span className={classes.itemTitle} data-cy-text={`comment-${item.name}`}>
                  {item.name.replace(/(<([^>]+)>)/gi, '')}
                </span>
              </span>
              <div style={{ minWidth: '70px' }}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(openCommentInfoModal({ comment: item }));
                  }}
                  className={classes.actionButton}
                >
                  <InfoIcon />
                </IconButton>
                <IconButton
                  id={`item${idx}`}
                  data-idx={idx}
                  data-deficiencies={isDeficiencies}
                  onClick={onOpenMenu}
                  className={classes.actionButton}
                  data-cy-button={`comment-menu-${item.name}`}
                >
                  <MoreIcon className={classes.moreIcon} />
                </IconButton>
              </div>
            </AccordionSummary>
            <AccordionDetails
              className={cls(classes.accordionContentWrapper, { [classes.accordionWrapperSelected]: isExpanded })}
              data-cy-container={`comment-details-${item.name}`}
            >
              {isExpanded && (
                <React.Fragment>
                  <CommentForm
                    onToggleImageLoading={toggleIsImageLoading}
                    onToggleVideoLoading={toggleIsVideoLoading}
                    onChangeComments={onChangeComments}
                    idx={idx}
                    selectedItem={selectedItem}
                    selectedSection={selectedSection}
                    estimations={estimations}
                    estimationTypes={estimationTypes}
                    commentRaw={item}
                    isReport={isReport}
                    isDeficiencies={isDeficiencies}
                    setAllDeafaultValues={setAllDeafaultValues}
                  />
                  <div className={classes.submitButtonWrapper}>
                    {!isReport && (
                      <Button
                        disabled={isImageLoading || isVideoLoading || (isReport && !isValid)}
                        className={classes.submitButton}
                        onClick={() => setTimeout(handleSubmit, 100)}
                        data-cy-button='submit-edit-comment'
                      >
                        SUBMIT
                      </Button>
                    )}

                    {isReport && item.isDone && (
                      <Button
                        className={classes.disableButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          setValues({ ...values, isDone: false });
                        }}
                      >
                        DISABLE
                      </Button>
                    )}
                  </div>
                </React.Fragment>
              )}
            </AccordionDetails>
          </Accordion>
        </Form>
      )}
    </Formik>
  );
};

export default Comment;

const SubmitValues = ({ values, initialValues, submit }) => {
  const v = { ...values };
  const i = { ...initialValues };
  const debouncedValues = useDebounce(values, 6);

  useEffect(() => {
    // console.log({ initialValues, values });
    const isDefaultValueEmpty =
      (i?.defaultValue === undefined || i?.defaultValue === null) &&
      (v?.defaultValue === undefined || v?.defaultValue === null);
    const isDefaultValueString = typeof i?.defaultValue === 'string' && typeof v?.defaultValue === 'string';
    const shouldUpdate =
      (!isDefaultValueEmpty && i?.defaultValue?.length !== v?.defaultValue?.length) ||
      (isDefaultValueString && i?.defaultValue !== v?.defaultValue);
    delete i.defaultValue;
    delete v.defaultValue;
    i.photos = JSON.stringify(i.photos);
    i.videos = JSON.stringify(i.videos);
    v.photos = JSON.stringify(v.photos);
    v.videos = JSON.stringify(v.videos);
    i.notes = (i.notes || '').replaceAll('&nbsp;', '');
    v.notes = (v.notes || '').replaceAll('&nbsp;', '');
    // console.log({ i, v });
    if (!_.isEqual(i, v) || shouldUpdate) {
      // ({ i, v, initialValues, values });
      submit(debouncedValues);
    }
  }, [debouncedValues]);
  return <></>;
};
